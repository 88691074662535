<template>
  <svg
    class="folder-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 191 159"
  >
    <defs></defs>
    <g id="closed">
      <path
        class="folder-icon-outline"
        d="M159,30.5H82.5c-3,0-7-1-9-5s-4.67-6-8.83-6H31a12,12,0,0,0-12,12V127a12,12,0,0,0,12,12H159a12,12,0,0,0,12-12V42.5A12,12,0,0,0,159,30.5Z"
      ></path>
      <path
        class="folder-icon-bg"
        d="M159,30.5H82.5c-3,0-7-1-9-5s-4.67-6-8.83-6H31a12,12,0,0,0-12,12V127a12,12,0,0,0,12,12H159a12,12,0,0,0,12-12V42.5A12,12,0,0,0,159,30.5Z"
      ></path>
      <rect
        class="folder-icon-outline"
        x="19"
        y="43"
        width="152"
        height="96"
        rx="12"
        ry="12"
      ></rect>
      <rect
        class="folder-icon-fg"
        x="19"
        y="43"
        width="152"
        height="96"
        rx="12"
        ry="12"
      ></rect>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style src="../styles/Folder.scss" lang="scss"></style>
